const CONFIG = {
	//开发环境配置
	development: {
		baseUrl: 'http://192.168.8.29:8003', // 后台接口请求地址
		// baseUrl: 'http://192.168.8.29:8088', // 后台接口请求地址
		// baseUrl: 'http://192.168.8.29:8087', // 后台接口请求地址
		// baseUrl: 'http://192.168.8.23:8003', // 后台接口请求地址
		// baseUrl: 'http://192.168.8.23:8088', // 后台接口请求地址
		// baseUrl: 'http://e-rijiben.com:8003', // 后台接口请求地址
		// baseUrl: 'http://e-rijiben.com:8003', // 后台接口请求地址
		// baseUrl: 'https://api.monitor.mz-biotech.com/gc', // 后台接口请求地址
	},
	//生产环境配置
	production: {
		baseUrl: 'https://api.monitor.mz-biotech.com', // 消毒机后台
		// baseUrl: 'https://api.monitor.mz-biotech.com/gc', // 厕所后台
	}
};
export default CONFIG[process.env.NODE_ENV];
